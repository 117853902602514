import React from "react"
import { Link } from "react-router-dom"
import MailchimpSignUp from "./MailchimpSignUp"
import Logo from "../assets/img/SCLogo.svg"

const MobileBottom = (props) => {
    return(
        <div className="MobileBottom NoDisplayDesktop">
            <h3 className="SectionTitle">Sign Up For Email Updates</h3>
            <div className="EmailUpdatesMobile">
                {/* <form>
                    <input required="required" placeholder="Jane Doe" type='text' name="FNAME" className="" id="mce-FNAME" />
                    <label className="label" for="UserNameField">First Name</label>
                    <input required="required" placeholder="Jane Doe" type='text' name="LNAME" className="" id="mce-LNAME" />
                    <label className="label" for="UserNameField">Last Name</label>
                    <input required="required" placeholder="youremail@example.com" type="text" id="UserEmailField" />
                    <label className="label" for="UserEmailField">Your Email Address</label>
                    <button className="SubmitButton" type='submit'>Submit</button>
                </form> */}
                <MailchimpSignUp />
            </div>
            <div className="Footer">
                <img src={Logo} alt="Shatttered Ceiling Scholarship Award" />
                <div className="FooterNav">
                    <Link to="/"><h4>Home</h4></Link>
                    <Link to="/about"><h4>About</h4></Link>
                    <Link to="/apply"><h4>Apply</h4></Link>
                    <Link to="/contact"><h4>Contact</h4></Link>
                </div>
                <button onClick={() => props.handleShow()} className="DonateButton" type="button">Donate</button>
            </div>
        </div>
    )
}

export default MobileBottom;