import React, {Component} from "react";
import { Link } from "react-router-dom";
import MailchimpSignUp from "./MailchimpSignUp";

// Image Imports
import Hamburger from "../assets/img/Hamburger_icon.png";
import Close from "../assets/img/cross-small-01-512.webp";
import Logo from "../assets/img/SCLogo.svg"


class Navbar extends Component {

    state = {
        isToggled: "untoggled",
        img: Hamburger,
    };

    expandMenu = () => {
        if (this.state.isToggled === "untoggled") {
            this.setState({isToggled: 'toggled'});
            this.setState({img: Close});
        }
        else if (this.state.isToggled === "toggled") {
            this.setState({isToggled: 'untoggled'});
            this.setState({img: Hamburger});
        }
        
    }

    handleLinkClick = () => {
        this.setState({isToggled: 'untoggled'});
        this.setState({img: Hamburger});
    }


    render() {
        return(
            <div>
                <nav className="Navbar">
                        <img className="Logo" src={Logo} alt="Shattered Ceiling Scholarship Award" />
                        <div className="NavContainer">
                            <div className="NavButtonContainer">
                                <Link to="/" className="NavButton">Home</Link>
                                <Link to="/about" className="NavButton">About</Link>
                                <Link to="/apply" className="NavButton">Apply</Link>
                                <Link to="/contact" className="NavButton">Contact</Link>
                            </div>
                            <button onClick={() => this.props.handleShowDonate()} className="NavDonateButton">Donate</button>
                            <p>for tax deductible donations, donate through <a href="http://www.know-agenda.org/fiscal-sponsorship.html">Know Agenda Foundation</a></p>
                            {/* <form className="NavEmail">
                                <h3>Sign up For Email Updates</h3>
                                <div className="InputContainer">
                                    <input required="required" placeholder="Jane Doe" className="InputFull" id="SignUpName" type="text" />
                                    <p>Your Name</p>
                                </div>
                                <div className="InputContainer">
                                    <input required="required" placeholder="email@example.com" className="InputFull" id="SignUpEmail" type="email" />
                                    <p>Your Email Address</p>
                                </div>
                                <button type="submit" className="NavSubmitButton">Submit</button>
                            </form> */}
                            <h3><a href="#" onClick={() => this.props.handleShowEmail()}>Sign up For Email Updates</a></h3>
                        </div>
                    <button onClick={() => this.expandMenu()} className="toggleButton NoDisplayDesktop">
                        <img className="menuToggleButton" src={this.state.img} alt="Menu Button" />
                    </button>
                    <div className={"MobileMenu" + ' ' + this.state.isToggled + ' ' + "NoDisplayDesktop"}>
                        <Link className="link" to="/">
                            <div onClick={() => this.handleLinkClick()} className="LinkDiv">
                                <h3>Home</h3>
                            </div>
                        </Link>
                        <Link className="link" to="/about">
                            <div onClick={() => this.handleLinkClick()} className="LinkDiv">
                                <h3>About</h3>
                            </div>
                        </Link>
                        <Link className="link" to="/apply">
                            <div onClick={() => this.handleLinkClick()} className="LinkDiv">
                                <h3>Apply</h3>
                            </div>
                        </Link>
                        <Link className="link" to="/contact">
                            <div onClick={() => this.handleLinkClick()} className="LinkDiv">
                                <h3>Contact</h3>
                            </div>
                        </Link>
                        <span className="ButtonContainer">
                            <button onClick={() => this.props.handleShowDonate()} type="button">Donate</button>
                        </span>
                        <span className="ButtonContainer">
                            <p>for tax deductible donations, donate through <a href="http://www.know-agenda.org/fiscal-sponsorship.html">Know Agenda Foundation</a></p>
                        </span>
                    </div>
                </nav>
            </div>
        );
    }
}

export default Navbar;