import React, {Component} from "react"
import TopArea from "./components/TopArea"
import Uploader from "./components/Uploader"

import TopImage from "./assets/img/applyTop.jpg"

class Apply extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            formValues: {
                studentFirstName: '',
                studentMI: '',
                studentLastName: '',
                studentDOB: '',
                studentPhone: '',
                studentEmail: '',
                studentAddress: '',
                studentAddress2: '',
                studentCity: '',
                studentState: '',
                studentZip: '',
                parentFirstName: '',
                parentMI: '',
                parentLastName: '',
                parentPhone: '',
                parentAddress: '',
                parentAddress2: '',
                parentCity: '',
                parentState: '',
                parentZip: '',
                grossIncome: '',
                HSName: '',
                HSAddress: '',
                HSAddress2: '',
                HSCity: '',
                HSState: '',
                HSZip: '',
                GPA: '',
                awards: '',
                hobbies: '',
                volunteering: '',
                GCName: '',
                GCHS: '',
                GCPhone: '',
                colleges: '',
                FoS: '',
                scholarships: ''
            },
            isSubmitting: false,
            submitError: null,
            submitSuccess: false,
            currentYear: ''
        };
        
        this.formRef = React.createRef();
    }

    handleChange = (e) => {
        this.setState(prevState => ({
            formValues: {
                ...prevState.formValues,
                [e.target.name]: e.target.value
            }
        }));
    }

    componentDidMount() {
        const year = new Date().getFullYear()
        this.setState({
            currentYear: year.toString()
        })
        if (window.localStorage.getItem('applicationData') !== null) {
            try {
                const formData = JSON.parse(window.localStorage.getItem('applicationData'));
                this.setState({
                    formValues: formData
                });
            } catch (error) {
                console.error("Error parsing saved application data:", error);
                // If there's an error parsing, clear the invalid data
                window.localStorage.removeItem('applicationData');
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // Fixed comparison condition - was using undefined this.prevState
        if (this.state.formValues !== prevState.formValues) {
            window.localStorage.setItem('applicationData', JSON.stringify(this.state.formValues));
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        
        this.setState({ isSubmitting: true, submitError: null });
        
        try {
            // Get the form element
            const form = e.target;
            const formData = new FormData(form);
            
            const response = await fetch('/application', {
                method: 'POST',
                body: formData,
                // Don't set Content-Type with FormData - browser handles it
            });
            
            if (!response.ok) {
                let errorMessage = 'Failed to submit application';
                try {
                    const errorData = await response.json();
                    errorMessage = errorData.message || errorMessage;
                } catch (_) {
                    // If response can't be parsed as JSON, use default error message
                }
                throw new Error(errorMessage);
            }
            
            this.setState({ submitSuccess: true });
            alert("Application submitted successfully!");
            
            // Clear saved form data from localStorage
            window.localStorage.removeItem('applicationData');
            
            // Redirect to success page
            window.location.href = '/success';
            
        } catch (error) {
            console.error("Error submitting form:", error);
            this.setState({ submitError: error.message });
            alert("There was an error submitting the form: " + error.message);
        } finally {
            this.setState({ isSubmitting: false });
        }
    }

    render() {
        const { formValues, isSubmitting, submitError, submitSuccess } = this.state;
        
        return(
            <div className="Apply">
                <TopArea image={TopImage} alt='' title="Apply"/>
                <div className="ScholarshipInfo">
                    <p className="paragraph">The Shattered Ceiling Scholarship Award announces the 4th Annual 2020  Shattered Ceiling Scholarship Award.  One (1) $2000 scholarship will be awarded to a female senior attending a high school in Portland, Oregon metropolitan area.</p>
                    <p className="paragraph">The Shattered Ceiling Scholarship Award was created by Angela Freeman. Each December since 2006, Angela honors her relationships with her closest friends through an annual ladies gathering. In 2016 she decided that every young lady should know how it feels to be surrounded by positive, and inspirational women. Women who truly live, laugh and love. Most of all, these women are trailblazers who want to make a difference.  With the help of some of the strongest, most compassionate and kick-ass ladies, Angela has collected funds to support one (1) like-minded high school senior that is determined to follow her dreams and shatter that glass ceiling!</p>
                    <p className="paragraph">Program Guidelines and Priorities: <br/>
    * Seeking graduating female seniors.<br/>
    * Applicants must plan to attend a two (2)-year community college or four (4)-year college or university.<br/>
    * Scholarship funds will be paid during the month of August {this.state.currentYear}—directly to the college or the student depending on the financial need. The scholarship funds will be issued to the college, university or the student upon receiving a certificate of enrollment for the fall semester, which includes the Student ID number and Financial Aid Office address.<br/>
    * Applicants must have the endorsement of their Guidance Counselor on their application attesting they are qualified for this scholarship program.<br/>
    * Applications must be received by the Shattered Ceiling Scholarship Award Committee c/o Angela Freeman no later than April 1, {this.state.currentYear}.  Late applications will not be accepted. </p>
                    <p className="paragraph">The applications will be reviewed and recipients selected by the Shattered Ceiling Scholarship Award Committee.  The scholarships will be awarded during the first week of June 2020 during a social gathering of women in Northeast Portland. A formal invitation will be sent in May and scholarship winners should plan to attend.</p>
                    <p className="paragraph">Please submit any questions to: <a href="mailto:angelareafreeman@gmail.com">angelareafreeman@gmail.com</a></p>
                </div>
                <h2 className="SectionTitle">Application</h2>
                <div className="ApplicationForm">
                    {submitError && (
                        <div className="ErrorMessage">
                            Error: {submitError}
                        </div>
                    )}
                    {submitSuccess && (
                        <div className="SuccessMessage">
                            Your application has been submitted successfully!
                        </div>
                    )}
                    
                    <form 
                        ref={this.formRef}
                        className="js-form" 
                        onSubmit={this.handleSubmit} 
                        encType="multipart/form-data"
                    >
                        <div className="AppSectionContainer">
                            <h4 className="AppSectionTitle">Student Information</h4>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.studentFirstName || ''} onChange={this.handleChange} required className="InputHalf" id="StudentFirstName" name="studentFirstName" type="text" />
                            <label htmlFor="StudentFirstName">Student's First Name</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.studentMI || ''} onChange={this.handleChange} className="InputEighth" id="StudentMI" name="studentMI" type="text" />
                            <label htmlFor="StudentMI">M.I.</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.studentLastName || ''} onChange={this.handleChange} required className="InputHalf" id="StudentLastName" name="studentLastName" type="text" />
                            <label htmlFor="StudentLastName">Student's Last Name</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.studentDOB || ''} onChange={this.handleChange} required className="InputHalf" id="StudentDateOfBirth" name="studentDOB" type="date" />
                            <label htmlFor="StudentDateOfBirth">Student's Date of Birth</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.studentPhone || ''} onChange={this.handleChange} required className="InputHalf" id="StudentPhoneNumber" name="studentPhone" type="tel" />
                            <label htmlFor="StudentPhoneNumber">Phone Number</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.studentEmail} onChange={this.handleChange} required="required" className="InputFull" id="StudentEmail" name="studentEmail" type="email" />
                            <label for="StudentEmail">Email Address</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.studentAddress} onChange={this.handleChange} required="required" className="InputFull" id="StudentStreetAddress" name="studentAddress" type="text" />
                            <label for="StudentStreetAddress">Street Address</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.studentAddress2} onChange={this.handleChange} className="InputHalf" id="StudentLine2" name="studentAddress2" type="text" />
                            <label for="StudentLine2"> Street Address Line 2</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.studentCity} onChange={this.handleChange} required="required" className="InputHalf" id="StudentCity" name="studentCity" type="text" />
                            <label for="StudentCity">City</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.studentState} onChange={this.handleChange} required="required" className="InputHalf" id="StudentState" name="studentState" type="text" />
                            <label for="StudentState">State</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.studentZip} onChange={this.handleChange} required="required" className="InputHalf" id="StudentZip" name="studentZip" type="text" />
                            <label for="StudentZip">Zip Code</label>
                        </div>
                        <div className="AppSectionContainer">
                            <h4 className="AppSectionTitle">Family Information</h4>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.parentFirstName} onChange={this.handleChange} required="required" className="InputHalf" id="ParentFirstName" name="parentFirstName" type="text" />
                            <label for="ParentFirstName">Parent/Guardian's First Name</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.parentMI} onChange={this.handleChange} className="InputEighth" id="ParentMI" name="parentMI" type="text" />
                            <label for="ParentMI">Parent/Guardian's M.I.</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.parentLastName} onChange={this.handleChange} required="required" className="InputHalf" id="ParentLastName" name="parentLastName" type="text" />
                            <label for="ParentLastName">Parent/Guardian's Last Name</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.parentPhone} onChange={this.handleChange} required="required" className="InputHalf" id="ParentPhone" name="parentPhone" type="tel" />
                            <label for="ParentPhone">Parent/Guardian's Phone Number</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.parentAddress} onChange={this.handleChange} className="InputFull" id="ParentStreetAddress" name="parentAddress" type="text" />
                            <label for="ParentStreetAddress">Parent/Guardian's Street Address (if different from own)</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.parentAddress2} onChange={this.handleChange} className="InputFull" id="ParentStreetAddressLine2" name="parentAddress2" type="text" />
                            <label for="ParentStreetAddressLine2">Street Address Line 2</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.parentCity} onChange={this.handleChange} required="required" className="InputHalf" id="ParentCity" name="parentCity" type="text" />
                            <label for="ParentCity">City</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.parentState} onChange={this.handleChange} required="required" className="InputHalf" id="ParentState" name="parentState" type="text" />
                            <label for="ParenttState">State</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.parentZip} onChange={this.handleChange} required="required" className="InputHalf" id="ParentZip" name="parentZip" type="text" />
                            <label for="ParentZip">Zip Code</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.grossIncome} onChange={this.handleChange} required="required" className="InputFull" id="GrossIncome" name="grossIncome" type="text" />
                            <label for="GrossIncome">Family Gross Annual Income from latest Income Tax form 1040 Line #22. (Written like e.g. $25000)</label>
                        </div>
                        <div className="AppSectionContainer">
                            <h4 className="AppSectionTitle">Academic Information</h4>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.HSName} onChange={this.handleChange} required="required" className="InputFull" id="HSName" name="HSName" type="text" />
                            <label for="HSName">Name of High School Attended</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.HSAddress} onChange={this.handleChange} required="required" className="InputFull" id="HSStreetAddress" name="HSAddress" type="text" />
                            <label for="HSStreetAddress">Street Address of High School</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.HSAddress2} onChange={this.handleChange} className="InputFull" id="HSStreetAddressLine2" name="HSAddress2" type="text" />
                            <label for="HSStreetAddressLine2">Street Address Line 2</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.HSCity} onChange={this.handleChange} required="required" className="InputHalf" id="HSCity" name="HSCity" type="text" />
                            <label for="HSCity">City</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.HSState} onChange={this.handleChange} required="required" className="InputHalf" id="HSState" name="HSState" type="text" />
                            <label for="HSState">State</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.HSZip} onChange={this.handleChange} required="required" className="InputHalf" id="HSZip" name="HSZip" type="text" />
                            <label for="HSZip">Zip Code</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.GPA} onChange={this.handleChange} required="required" className="InputFourth" id="GPA" name="GPA" type="text" />
                            <label for="GPA">Cumulative Grade Point Average (GPA) (On a 4.0 scale)</label>
                        </div>
                        <div className="InputContainer">
                            <textarea value={this.state.formValues.awards} onChange={this.handleChange} required="required" className="TextArea" id="Awards" name="awards" type="text" />
                            <label for="Awards">List any academic honors, awards and membership activities while in high school.</label>
                        </div>
                        <div className="InputContainer">
                            <textarea value={this.state.formValues.hobbies} onChange={this.handleChange} required="required" className="TextArea" id="Hobbies" name="hobbies" type="text" />
                            <label for="Hobbies">List your hobbies, outside interests, extracurricular activities and school related volunteer activities.</label>
                        </div>
                        <div className="InputContainer">
                            <textarea value={this.state.formValues.volunteering} onChange={this.handleChange} required="required" className="TextArea" id="Volunteering" name="volunteering" type="text" />
                            <label for="Volunteering">List your non-school sponsored volunteer activities in the community.</label>
                        </div>
                        <div className="AppSectionContainer">
                            <h4 className="AppSectionTitle">Guidance Counselor Info</h4>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.GCName} onChange={this.handleChange} required="required" className="InputFull" id="GCName" name="GCName" type="text" />
                            <label for="GCName">Full name of Guidance Counselor</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.GCHS} onChange={this.handleChange} required="required" className="InputFull" id="GCHS" name="GCHS" type="text" />
                            <label for="GCHS">High School of Guidance Counselor</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.GCPhone} onChange={this.handleChange} required="required" className="InputHalf" id="GCPhone" name="GCPhone" type="tel" />
                            <label for="GCPhone">Phone Number of Guidance Counselor</label>
                        </div>
                        <div className="AppSectionContainer">
                            <h4 className="AppSectionTitle">Plans For College</h4>
                        </div>
                        <div className="InputContainer">
                            <textarea value={this.state.formValues.colleges} onChange={this.handleChange} required="required" className="TextArea" id="Colleges" name="colleges" type="text" />
                            <label for="Colleges">If you have decided on the college or university you will attend, please list the school name. If not, list your top three (3) college/university choices.</label>
                        </div>
                        <div className="InputContainer">
                            <input value={this.state.formValues.FoS} onChange={this.handleChange} required="required" className="InputFull" id="FieldOfStudy" name="FoS" type="text" />
                            <label for="FieldOfStudy">Anticipated Field of Study</label>
                        </div>
                        <div className="InputContainer">
                            <textarea value={this.state.formValues.scholarships} onChange={this.handleChange} required="required" className="TextArea" id="Scholarships" name="scholarships" type="text" />
                            <label for="Scholarships">Please list any other scholarships applied for and any awarded.</label>
                        </div>
                        <div className="AppSectionContainer">
                            <h4 className="AppSectionTitle">Uploads</h4>
                        </div>
                        <Uploader id="essay" paragraph={"Please write and upload an essay (300 - 700 words) addressing one (1) of the following topics. (PDF only)" + '\n \n' + "1) What are the benefits of having strong female role models for young women in America?" + '\n \n' + "2) Discuss any challenges you have dealt with and overcome and how that will help you succeed in college and beyond."} />
                        <Uploader id="recLetter1" paragraph="One (1) letter of recommendation from a woman in your life that you admire." />
                        <Uploader id="recLetter2" paragraph="One (1) letter of recommendation from a teacher, counselor or principal at your school." />
                        <div className="AppSectionContainer">
                            <h4 className="AppSectionTitle">Signature</h4>
                        </div>
                        <p className="paragraph">{"I hereby affirm that all the above stated information provided by me is true and correct to the best of my knowledge.  I also consent, that if chosen as a scholarship winner, my picture may be taken and used to promote the Shattered Ceiling Scholarship Award. (Winner may waive photo due to unusual or compelling circumstances.)" + '\n \n' + "I hereby understand that if chosen as a scholarship winner, I will try to be present at any June 2020 awards ceremony and/or reception to receive my scholarship award certificate." + '\n \n' + "I hereby understand that if chosen as a scholarship winner, it is my responsibility to submit to Angela Freeman (owner of the Shattered Ceiling account) no later than August 30, 2020, a certificate or verification of enrollment for the fall semester, which includes the Student ID number and Financial Aid Office address." + '\n \n' + "I hereby understand I will not submit this application without all required attachments and supporting information. Incomplete applications or applications that do not meet eligibility criteria will not be considered for this scholarship." + '\n \n' + "Your full name and today's date entered below will serve as your electronic signature."}</p>
                        <div className="InputContainer">
                            <input 
                                value={formValues.sigName || ''} 
                                onChange={this.handleChange} 
                                required 
                                className="InputFull" 
                                id="SignatureName" 
                                name="sigName" 
                                type="text" 
                            />
                            <label htmlFor="SignatureName">Full Legal Name</label>
                        </div>
                        <div className="InputContainer">
                            <input 
                                value={formValues.sigDate || ''} 
                                onChange={this.handleChange} 
                                required 
                                className="InputFull" 
                                id="SignatureDate" 
                                name="sigDate" 
                                type="date" 
                            />
                            <label htmlFor="SignatureDate">Today's Date</label>
                        </div>
                        <button 
                            className="SubmitButton" 
                            type="submit"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Submitting...' : 'Submit'}
                        </button>
                    </form>
                </div>
            </div>
        )
    }
}

export default Apply;