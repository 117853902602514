import React from "react"
import TopArea from "./components/TopArea"

import TopImage from "./assets/img/applyTop.jpg"

const ApplySuccess = () => {
    return(
        <div className="Apply">
            <TopArea image={TopImage} alt='' title="Apply"/>
            <div className="Success">
                <h2>Thank You</h2>
                <p>We have received your application.</p>
            </div>
        </div>
    )    
}

export default ApplySuccess;