import React, { useState } from "react"

const Uploader = (props) => {
    const [fileName, setFileName] = useState("");
    
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFileName(file.name);
        } else {
            setFileName("");
        }
    };
    
    return(
        <div className="Uploader">
            <p className="paragraph">{props.paragraph}</p>
            <div className="FileInputWrapper">
                <input 
                    required="required" 
                    type="file" 
                    id={props.id} 
                    name={props.id} 
                    onChange={handleFileChange}
                    accept=".pdf"
                />
                {fileName && (
                    <span className="SelectedFile">Selected: {fileName}</span>
                )}
            </div>
        </div>
    )
}

export default Uploader;