import Alondra from "../assets/img/alumni/AlondraRectangle.png"
import Imani from "../assets/img/alumni/ImageNeeded.png"
import Anna from "../assets/img/alumni/AnnaRectangle.png"
import Amaya from "../assets/img/alumni/AmayaRectangle.png"
import Len from "../assets/img/alumni/LenRectangle.png"
import Carina from "../assets/img/alumni/ImageNeeded.png"

var alumniList = [
    {
        "name": "Alondra Laureano",
        "year": "2017",
        "quote": "\"I’m a Mechanical Engineering student at Portland State University, with a passion for sustainability. I was very lucky to receive the Shattered Ceiling Scholarship in June 2017, and words cannot explain how grateful I am for Angela and her community for this opportunity. I met many wonderful women through this scholarship and was able to purchase my first laptop for university. This scholarship truly changed my university experience and I am so grateful as it has led me to many more exciting opportunities.\"",
        "image": Alondra
    },
    // {
    //     "name": "Imani Wolery",
    //     "year": "2018",
    //     "quote": "\"Quote from recipient\"",
    //     "image": Imani
    // },
    {
        "name": "Anna Merrill",
        "year": "2019",
        "quote": "\"The Shattered Ceiling Scholarship Award was an incredible scholarship that I will always be grateful for. This award and it’s message, as well as the incredible women behind it, made me feel so supported and heard during a time of need. They also gave me the confidence and courage I needed as a young woman about to enter her first year of college. I will always be thankful for this opportunity, and would highly encourage any eligible young women to apply for this award.\"",
        "image": Anna
    },
    {
        "name": "Amaya Gustave",
        "year": "2019",
        "quote": "\"I am now a rising junior at Pitzer College studying Organizational Studies and Spanish. The Shattered Ceiling Scholarship gave me the strength to truly acknowledge and reflect on how the obstacles in my life have shaped me. This scholarship additionally helped to support my path of higher education and radical self-love. My desire to live my truth intensified. Being able to meet the other recipients, exchange stories, goals, and experiences are memories I will always hold close to my heart. My current obsessions are fantasy novels, chai tea and morning walks!\"",
        "image": Amaya
    },
    {
        "name": "Len Nguyen",
        "year": "2019",
        "quote": "\"The Shattered Ceiling Scholarship has helped me pursue my dream of becoming a pharmacist at Northeastern University in Boston, MA. I've just completed my pre-pharm years and will be getting my white coat in fall 2021! Right now during the Summer I am working as an intern at an independent pharmacy, but in 4 years I will be a liscensed pharmacist. Thanks to Dr. Freeman and the scholarship committee for believing in me and helping me reach my goals!\"",
        "image": Len
    },
    // {
    //     "name": "Carina",
    //     "year": "2020",
    //     "quote": "\"Quote from Recipient\"",
    //     "image": Carina
    // }
]

export default alumniList;