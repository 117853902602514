import Angela from "../assets/img/board/angela.jpg"
import Vicki from "../assets/img/board/Vicki.png"
import Leslee from "../assets/img/board/Leslee.png"
import Gail from "../assets/img/board/Gail.png"

var boardList = [
    {
        name: "Angela Freeman",
        role: "Founder",
        title: 'Assistant Superintendant, Reynolds School District',
        image: Angela
    },
    {
        name: "Gail Warren",
        role: "Board Member",
        title: 'Confidential Executive Assistant, Portland Public Schools',
        image: Gail
    },
    {
        name: "Vicki Guinn",
        role: "Board Member",
        title: 'Communications Strategist, Legacy Health',
        image: Vicki
    },
    {
        name: "Leslee Barnes",
        role: "Board Member",
        title: 'Preschool and Early Learning Division Director, Multnomah County',
        image: Leslee
    }
]

export default boardList;