import React, {Component} from "react"
import './styles/styles.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./Home";
import About from "./About";
import Apply from "./ApplyClass";
import ApplySuccess from "./ApplySuccess";
import Contact from "./Contact";
import MobileBottom from './components/MobileBottom';
import ScrollToTop from "./ScrollToTop";
import { Modal } from "react-bootstrap";
import MailchimpSignUp from "./components/MailchimpSignUp";


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showDonate: false,
      showEmail: false
    };
  }

  handleCloseDonate = () => {
    this.setState({showDonate: false})
  }

  handleShowDonate = () => {
    this.setState({showDonate: true})
  }

  handleCloseEmail = () => {
    this.setState({showEmail: false})
  }

  handleShowEmail = () => {
    this.setState({showEmail: true})
  }

  render() {
    return (
      <Router>
        <ScrollToTop />
        <div className="App">
            <Modal className="DonateModal" show={this.state.showDonate} onHide={this.handleCloseDonate}>
              <Modal.Header closeButton>
                <Modal.Title>Make a Donation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <iframe src="https://givebutter.com/embed/c/aO9sa1" width="100%" height="615px" style={{maxWidth: '601px'}} name="givebutter" frameborder="0" scrolling="yes" seamless allowpaymentrequest></iframe>
              </Modal.Body>
              <Modal.Footer>
              </Modal.Footer>
            </Modal>
            <Modal className="DonateModal" show={this.state.showEmail} onHide={this.handleCloseEmail}>
              <Modal.Header closeButton>
                <Modal.Title>Subscribe to Our Email List</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <MailchimpSignUp />
              </Modal.Body>
              <Modal.Footer>
              </Modal.Footer>
            </Modal>
            <Navbar handleShowDonate={this.handleShowDonate} handleShowEmail={this.handleShowEmail} />
            <div className="Content">
                <Switch>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route path="/about">
                    <About />
                  </Route>
                  <Route path="/apply">
                    <Apply />
                  </Route>
                  <Route path="/success">
                    <ApplySuccess />
                  </Route>
                  <Route path="/contact">
                    <Contact />
                  </Route>
                </Switch>
            </div>
            <MobileBottom handleShow={this.handleShowDonate}  />
        </div>
      </Router>
    );
  }
}

export default App;
